import { animated, useSpring, config } from '@react-spring/web'
import { lerp } from '@kaliber/math'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import styles from './Marquee.css'

export function MarqueeDark({ children }) {
  const { ref: scrollProgressionRef, progression } = useAnimatedScrollProgression()

  return (
    <div className={styles.componentDark} ref={scrollProgressionRef}>
      <MarqueeBase {...{ progression, children }} />
    </div>
  )
}

export function Marquee({ children }) {
  const { ref: scrollProgressionRef, progression } = useAnimatedScrollProgression()

  return (
    <div className={styles.component} ref={scrollProgressionRef}>
      <MarqueeBase {...{ progression, children }} />
    </div>
  )
}

function MarqueeBase({ progression, children }) {
  return (
    <div className={styles.componentBase}>
      <animated.div
        className={styles.items}
        style={{ transform: progression.to(x =>
          `translateX(${lerp({ start: 0, end: -100, input: x })}%)`)
        }}
      >
        <div className={styles.item}>
          {children}
        </div>
      </animated.div>
    </div>
  )
}

function useAnimatedScrollProgression() {
  const [{ progression }, spring] = useSpring(() => ({ progression: 0, config: config.molasses }))
  const ref = useScrollProgression({
    start: {
      element: triggers.top(),
      scrollParent: triggers.bottom()
    },
    end: { element: triggers.top(), scrollParent: triggers.top(100) },
    onChange(progression) {
      spring.start({
        progression: easeOutQuint(progression)
      })
    }
  })

  return { ref, progression }
}

function easeOutQuint(x) {
  return 1 - Math.cos((x * Math.PI) / 2)
}
