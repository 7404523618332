import { routeMap } from '/routeMap'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { ButtonLinkWhite, ButtonLinkDark } from '/features/buildingBlocks/Button'
import { Marquee } from '/features/buildingBlocks/Marquee'
import { useMediaQuery } from '@kaliber/use-media-query'
import { Icon } from '/features/buildingBlocks/Icon'
import mediaStyles from '/cssGlobal/media.css'
import lineTopRight from '/images/lines/line-banner.raw.svg'
import lineTopRightMobile from '/images/lines/line-banner-mobile.raw.svg'

import styles from './AboutBanner.css'

export function AboutBannerDark({ title, button }) {
  return (
    <AboutBannerBase
      dark
      className={styles.componentDark}
      {...{ title, button }}
    />
  )
}

export function AboutBannerLight({ title, button }) {
  return (
    <AboutBannerBase
      className={styles.componentLight}
      {...{ title, button }}
    />
  )
}

function AboutBannerBase({ title, button, className, dark = undefined }) {
  const viewportIsMd = useMediaQuery(mediaStyles.viewportMd)
  const ButtonType = dark ? ButtonLinkWhite : ButtonLinkDark
  return (
    <div className={cx(styles.componentBase, className)}>
      {title && (
        <div className={styles.marquee}>
          <Marquee>
            {title}
          </Marquee>
        </div>
      )}
      <Icon icon={viewportIsMd ? lineTopRight : lineTopRightMobile} layoutClassName={cx(styles.shape, dark && styles.isDark)} />
      {button?.ref && button?.label && (
        <ButtonType
          dataX='cta'
          layoutClassName={styles.button}
          href={button.ref && determineDocumentPathSync({ document: button.ref, routeMap })}
        >
          {button.label}
        </ButtonType>
      )}
    </div>
  )
}
